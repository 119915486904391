<template>
  <section id="dashboard">
    <b-row>
      <b-col>
        <h2>Patient Profile</h2>
      </b-col>
    </b-row>

    <b-row class="content-header">
      <b-col
        md="12"
        class="mb-2 text-right"
      >
        <router-link
          class="btn btn-secondary mr-3"
          type="button"
          :to="{ name: 'dashboard' }"
        >
          Back
        </router-link>
      </b-col>
    </b-row>

    <div
      class="container mt-3 bg-white rounded p-5"
    >
      <h2 class="mb-1">
        {{ patient.first_name }} {{ patient.last_name }}
      </h2>
      <h5 class="text-black-50">
        ID: {{ patient.uuid }}
      </h5>
      <h5 class="text-black-50">
        Reports Count: {{ patient.reports_count }}
      </h5>

      <b-row
        v-if="patient.clinicians && patient.clinicians.length > 0"
        class="match-height"
      >
        <b-col class="my-2">
          <h3 class="mb-2">
            Assigned Clinician:
          </h3>
          <h5 class="text-black-50">
            ID: {{ patient.clinicians[0].uuid }}
          </h5>
          <h5 class="text-black-50">
            Name: {{ patient.clinicians[0].name }}
          </h5>
          <h5 class="text-black-50 text-capitalize">
            Role: {{ patient.clinicians[0].role.name }}
          </h5>
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col
          md="12"
          class="my-1"
        >
          <h4 class="font-weight-bold">
            Select Type:
          </h4>
          <p>
            <!-- Show all types as button switches from selected date -->
            <b-button-group>
              <b-button
                v-for="(t, index) in types"
                :key="index"
                :variant="t === type ? 'primary' : 'secondary'"
                @click="showChart(t)"
              >
                <!-- show text from camelcase to capitalize -->
                {{ t | camelToNormal }}
              </b-button>
            </b-button-group>
          </p>
        </b-col>
        <b-col
          v-if="type"
          md="12"
          class="my-1"
        >
          <bar-chart
            :left-data="chartData.leftData"
            :right-data="chartData.rightData"
            :labels="chartData.labels"
            :report-title="chartTitle"
          />
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col class="my-2">
          <h3 class="mb-2">
            Reports
          </h3>
          <div class="table-responsive">
            <table class="table table-striped table-hover">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Angle</th>
                  <th>Type</th>
                  <th>Clinician</th>
                  <th>Side</th>
                  <th>Dated</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(report, index) in simpleReports"
                  :key="index"
                >
                  <td>
                    {{ report.id }}
                  </td>
                  <td>
                    {{ report.angle }}
                  </td>
                  <td>
                    {{ report.type | camelToNormal }}
                  </td>
                  <td>{{ report.clinician }}</td>
                  <td>{{ report.side }}</td>
                  <td>{{ report.timestamp }}</td>
                </tr>
              </tbody>
            </table>
            <b-pagination
              v-model="args.page"
              :total-rows="args.total"
              :per-page="args.size"
              align="center"
              class="my-1"
            />
          </div>
        </b-col>
      </b-row>

    </div>

  </section>
</template>

<script>
import {
  BButton, BButtonGroup, BCol, BPagination, BRow,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import BarChart from '../components/charts/ChartjsComponentBarChart.vue'

export default {
  components: {
    BRow,
    BCol,
    BPagination,
    BButton,
    BButtonGroup,
    BarChart,
  },
  filters: {
    camelToNormal(value) {
      return value.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())
    },
  },
  data() {
    return {
      patients: [],
      patient: {},
      cUuid: null,
      reports: [],
      formattedReports: {},
      simpleReports: [],
      chartTitle: {
        patientName: '',
        currentType: '',
        currentTypeReportCount: 0,
        mostRecentReport: {
          left: 0,
          right: 0,
          date: '',
        },
      },
      dates: [],
      types: [],
      date: null,
      type: null,
      chartData: {
        labels: [],
        leftData: [],
        rightData: [],
      },
      args: {
        page: 1,
        size: 20,
        total: 20,
      },
    }
  },
  mounted() {
    this.getPatient()
  },
  methods: {
    getPatient() {
      if (localStorage.getItem('patient') !== null) {
        this.patient = JSON.parse(localStorage.getItem('patient'))
        this.getReports()
      } else {
        this.$router.push({ name: 'view-patients' })
      }
    },
    getReports() {
      const pUuid = this.patient.uuid
      useJwt.getReports(pUuid, this.args).then(response => {
        this.reports = response.data.data
        this.args.total = response.data.total
        // reports is an array of arrays of objects. First we need to flatten it to an array of objects
        this.simpleReports = this.reports.flat()

        // Get all types from data and then group similar group ids in an array
        this.types = [...new Set(this.simpleReports.map(item => item.type))]

        // assign the types as the key names and empty array as value to formattedReports object
        this.types.forEach(type => {
          this.formattedReports[type] = []
        })

        this.types.forEach(t => {
          // loop through this.reports for a report and then match the type of first element of that report array
          this.reports.forEach(r => {
            if (r[0].type === t) {
              // if the type matches then push the report array to the formattedReports object
              this.formattedReports[t].push(r)
            }
          })
        })
      })
    },
    showChart(type) {
      this.type = type
      this.chartData.labels = []
      this.chartData.data = []
      this.chartData.barLabels = []
      this.formattedReports[type].forEach(report => {
        let time = report[0].timestamp.split(' ')[0]
        time = time.split('-').reverse().join('/')
        this.chartData.labels.push(time)
        this.chartData.leftData.push(report[0].angle)
        this.chartData.rightData.push(report[1].angle)
      })
      this.chartTitle.patientName = `${this.patient.first_name} ${this.patient.last_name}`
      // format the type to capitalize from camel cse
      this.chartTitle.currentType = type.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())
      this.chartTitle.currentTypeReportCount = this.formattedReports[type].length
      this.chartTitle.mostRecentReport.left = this.formattedReports[type][this.formattedReports[type].length - 1][0].angle
      this.chartTitle.mostRecentReport.right = this.formattedReports[type][this.formattedReports[type].length - 1][1].angle
      // eslint-disable-next-line prefer-destructuring
      this.chartTitle.mostRecentReport.date = this.formattedReports[type][this.formattedReports[type].length - 1][0].timestamp.split(' ')[0]
    },
  },
}
</script>

<style lang="scss">
#bar-chart{
  height: 560px !important;
}
// @import '@core/scss/vue/pages/dashboard-ecommerce.scss';
// @import '@core/scss/vue/libs/chart-apex.scss';
</style>
