<!-- eslint-disable -->
<script>
/*eslint-disable */
import { Bar } from 'vue-chartjs'
// const { reactiveProp } = mixins

export default {
  name: 'BarChart',
  extends: Bar,
  // mixins: [reactiveProp],
  props: {
    leftData: {
      type: Array,
      default: null,
    },
    rightData: {
      type: Array,
      default: null,
    },
    labels: {
      type: Array,
      default: null,
    },
    reportTitle: {
      type: Object,
      default: null,
    },
  },
  computed: {
    chartLeftData() {
      return this.leftData
    },
    chartRightData() {
      return this.rightData
    },
    chartLabel() {
      return this.labels
    },
    chartTitle() {
      return this.reportTitle
    },
  },
  watch: {
    data() {
      // eslint-disable-next-line no-underscore-dangle
      this.$data._chart.destroy()
      // this.renderChart(this.data, this.options);
      this.renderRadarChart()
    },
  },
  mounted() {
    this.renderRadarChart()
  },
  methods: {
    renderRadarChart() {
      let vm = this
      this.renderChart(
        {
          labels: this.chartLabel,
          datasets: [
            {
              label: 'Left',
              data: this.chartLeftData,
              backgroundColor: 'rgb(29,54,74)',
              borderColor: 'rgb(29,54,74, 0)',
              borderWidth: 1,
              pointRadius: 0,
            },
            {
              label: 'Right',
              data: this.chartRightData,
              backgroundColor: 'rgb(196, 161, 90)',
              borderColor: 'rgb(196, 161, 90, 0)',
              borderWidth: 1,
              pointRadius: 0,
            },
          ],
        },
        {
          categoryPercentage: 1,
          barPercentage: 0.5,
          elements: {
            rectangle: {
              borderWidth: 2,
              borderSkipped: 'bottom',
            },
          },
          animation: {
            duration: 0,
            onComplete: function() {
              var chartInstance = this.chart,
                  ctx = chartInstance.ctx;

              let totalX = chartInstance.scales['x-axis-0'].width;
              let chartTitle = vm.chartTitle;
              ctx.font = "bold 18px \"Montserrat\", Helvetica, Arial, serif"
              // Name of patient in bold
              ctx.fillText(chartTitle.patientName,5,20);
              // Current Type
              ctx.fillText(chartTitle.currentType,5,100);
              // Most Recent Left Value
              ctx.fillText(`${chartTitle.mostRecentReport.left}°`,totalX - 130,125);
              // Most Recent Right Value
              ctx.fillText(`${chartTitle.mostRecentReport.right}°`,totalX - 50,125);

              ctx.font = "normal 13px \"Montserrat\", Helvetica, Arial, serif"
              // Today's Date
              ctx.fillText(new Date().toLocaleDateString(),5,40);
              // Current Type's Total Reports Count Shown On Chart
              ctx.fillText(`Previous ${chartTitle.currentTypeReportCount} tests`,5,120);
              // Most Recent Report Date
              ctx.fillText("Most recent result: " + chartTitle.mostRecentReport.date,totalX - 200,100);

              ctx.font = "bold 12px \"Montserrat\", Helvetica, Arial, serif"
              // Left and Right Labels
              ctx.fillText("LEFT",totalX - 130,150);
              ctx.fillText("RIGHT",totalX - 50,150);

            }
          },
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 0,
          legend: {
            display: false,
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: 'rgba(0, 0, 0, 0.25)',
            backgroundColor: '#D0D4DB',
            titleFontColor: '#919191',
            bodyFontColor: '#919191',
          },
          scales: {
            xAxes: [
              {
                display: true,
                barThickness: 25,
                gridLines: {
                  display: false,
                },
                scaleLabel: {
                  display: false,
                },
                ticks: {
                  fontColor: '#6e6b7b',
                },
              },
            ],
            yAxes: [
              {
                display: true,
                gridLines: {
                  color: 'rgba(200, 200, 200, 0.2)',
                  zeroLineColor: 'rgba(200, 200, 200, 0.2)',
                },
                ticks: {
                  stepSize: 45,
                  min: 0,
                  max: 180,
                  fontColor: '#6e6b7b',
                },
              },
            ],
          },
          layout: {
            padding: {
              top: 200
            }
          }
        },
      )
    },
  },
}
</script>
<!--<script>
import { Bar } from 'vue-chartjs'
import Chart from 'chart.js'

/*eslint-disable */

// Chart.elements.Rectangle.prototype.draw = function () {
//   let ctx = this._chart.ctx
//   let viewVar = this._view
//   let left, right, top, bottom, signX, signY, borderSkipped, radius
//   let borderWidth = viewVar.borderWidth
//   let cornerRadius = 20
//   if (!viewVar.horizontal) {
//     left = viewVar.x - viewVar.width / 2
//     right = viewVar.x + viewVar.width / 2
//     top = viewVar.y
//     bottom = viewVar.base
//     signX = 1
//     signY = top > bottom ? 1 : -1
//     borderSkipped = viewVar.borderSkipped || 'bottom'
//   } else {
//     left = viewVar.base
//     right = viewVar.x
//     top = viewVar.y - viewVar.height / 2
//     bottom = viewVar.y + viewVar.height / 2
//     signX = right > left ? 1 : -1
//     signY = 1
//     borderSkipped = viewVar.borderSkipped || 'left'
//   }
//
//   if (borderWidth) {
//     let barSize = Math.min(Math.abs(left - right), Math.abs(top - bottom))
//     borderWidth = borderWidth > barSize ? barSize : borderWidth
//     let halfStroke = borderWidth / 2
//     let borderLeft = left + (borderSkipped !== 'left' ? halfStroke * signX : 0)
//     let borderRight = right + (borderSkipped !== 'right' ? -halfStroke * signX : 0)
//     let borderTop = top + (borderSkipped !== 'top' ? halfStroke * signY : 0)
//     let borderBottom = bottom + (borderSkipped !== 'bottom' ? -halfStroke * signY : 0)
//     if (borderLeft !== borderRight) {
//       top = borderTop
//       bottom = borderBottom
//     }
//     if (borderTop !== borderBottom) {
//       left = borderLeft
//       right = borderRight
//     }
//   }
//
//   ctx.beginPath()
//   ctx.fillStyle = viewVar.backgroundColor
//   ctx.strokeStyle = viewVar.borderColor
//   ctx.lineWidth = borderWidth
//   let corners = [
//     [left, bottom],
//     [left, top],
//     [right, top],
//     [right, bottom]
//   ]
//
//   let borders = ['bottom', 'left', 'top', 'right']
//   let startCorner = borders.indexOf(borderSkipped, 0)
//   if (startCorner === -1) {
//     startCorner = 0
//   }
//
//   function cornerAt(index) {
//     return corners[(startCorner + index) % 4]
//   }
//
//   let corner = cornerAt(0)
//   ctx.moveTo(corner[0], corner[1])
//
//   for (let i = 1; i < 4; i++) {
//     corner = cornerAt(i)
//     let nextCornerId = i + 1
//     if (nextCornerId == 4) {
//       nextCornerId = 0
//     }
//
//     let nextCorner = cornerAt(nextCornerId)
//
//     let width = corners[2][0] - corners[1][0],
//       height = corners[0][1] - corners[1][1],
//       x = corners[1][0],
//       y = corners[1][1]
//
//     let radius = cornerRadius
//
//     if (radius > height / 2) {
//       radius = height / 2
//     }
//     if (radius > width / 2) {
//       radius = width / 2
//     }
//
//     if (!viewVar.horizontal) {
//       ctx.moveTo(x + radius, y)
//       ctx.lineTo(x + width - radius, y)
//       ctx.quadraticCurveTo(x + width, y, x + width, y + radius)
//       ctx.lineTo(x + width, y + height - radius)
//       ctx.quadraticCurveTo(x + width, y + height, x + width, y + height)
//       ctx.lineTo(x + radius, y + height)
//       ctx.quadraticCurveTo(x, y + height, x, y + height)
//       ctx.lineTo(x, y + radius)
//       ctx.quadraticCurveTo(x, y, x + radius, y)
//     } else {
//       ctx.moveTo(x + radius, y)
//       ctx.lineTo(x + width - radius, y)
//       ctx.quadraticCurveTo(x + width, y, x + width, y + radius)
//       ctx.lineTo(x + width, y + height - radius)
//       ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height)
//       ctx.lineTo(x + radius, y + height)
//       ctx.quadraticCurveTo(x, y + height, x, y + height)
//       ctx.lineTo(x, y + radius)
//       ctx.quadraticCurveTo(x, y, x, y)
//     }
//   }
//
//   ctx.fill()
//   if (borderWidth) {
//     ctx.stroke()
//   }
// }
/* eslint-enable */

// export default {
//   extends: Bar,
//   props: {
//     data: {
//       type: Object,
//       default: null,
//     },
//     options: {
//       type: Object,
//       default: null,
//     },
//   },
//   watch: {
//     data() {
//       // eslint-disable-next-line no-underscore-dangle
//       this.$data._chart.destroy();
//       this.renderChart(this.data, this.options)
//     },
//   },
//   mounted() {
//     this.renderChart(this.data, this.options)
//   },
// }
</script>-->
